import React, { useState } from 'react';
import { View, Text, Button } from '../components/common';
import Layout from '../components/layout';
import Content from '../components/content';
import Loading from '../components/svg/AnimatedLoading';
import config from '../config';
import { colors } from '../style';

export default function Unsubscribe() {
  let [status, setStatus] = useState('initial');

  async function onUnsubscribe() {
    let m = location.search.match(/email=(.*)/);
    if (m) {
      let email = m[1];

      setStatus('loading');

      try {
        await fetch(config.endpoint + '/unsubscribe', {
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json());

        setStatus('unsubscribed');
      } catch (e) {
        setStatus('error');
      }
    }
  }

  return (
    <Layout>
      <Content>
        <View className="items-center mt-16">
          {status === 'loading' ? (
            <Loading style={{ width: 20, height: 20 }} color={colors.n1} />
          ) : status === 'error' ? (
            <View className="text-red-700">
              Very sorry about this, but we hit an error. Please you try again
              soon.
            </View>
          ) : status === 'unsubscribed' ? (
            <View>You have been unsubscribed.</View>
          ) : (
            <>
              {' '}
              <View>
                You will no longer receive any unsolicited email from us if you
                unsubscribe.
              </View>
              <Button primary className="mt-8" onClick={onUnsubscribe}>
                Unsubscribe
              </Button>
            </>
          )}
        </View>
      </Content>
    </Layout>
  );
}
